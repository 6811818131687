import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RedirectPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector((state) => state.user);

	useEffect(() => {
		const redirectPages = ['/login', '/signup', '/login/resetpassword'];
		const dynamicRoutes = [/^\/profile\/\d+$/];

		const isDynamicRoute = dynamicRoutes.some((pattern) =>
			pattern.test(location.pathname)
		);

		if (user !== null) {
			if (user === '') {
				if (
					!redirectPages.includes(location.pathname) &&
					!isDynamicRoute &&
					!location.pathname.includes('resetpassword')
				) {
					navigate('/login');
				}
			} else {
				if (redirectPages.includes(location.pathname)) {
					navigate('/');
				}
			}
		}
	}, [location, navigate, user]);

	return null;
};

export default RedirectPage;
