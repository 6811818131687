import axios from 'axios'
const baseUrl = '/api/browsing'

const getUsers = searchCriteria => {
	const request = axios.post(`${baseUrl}/sorted`, searchCriteria)
	return request.then(response => response.data)
}
const getRecommendedUsers = searchCriteria => {
	const request = axios.post(`${baseUrl}/recommended`, searchCriteria)
	return request.then(response => response.data)
}
const getPlans = () => {
	const request = axios.post(`/api/subscriptions/plans`)
	return request.then(response => response.data)
}
const subscribePlan = (payload) => {
	const request = axios.post(`/api/subscriptions/subscribe`, payload)
	return request.then(response => response.data)
}

const likeUser = user_id => {
	const request = axios.post(`${baseUrl}/initiateChat/${user_id}`)
	return request.then(response => response.data)
}
const likeUserV2 = user_id => {
	const request = axios.post(`${baseUrl}/like/${user_id}`)
	return request.then(response => response.data)
}
const adminDeleteUser = user_id => {
	const request = axios.post(`${baseUrl}/deleteChatV2/${user_id}`)
	return request.then(response => response.data)
}

const unlikeUser = user_id => {
	const request = axios.post(`${baseUrl}/deleteChat/${user_id}`)
	return request.then(response => response.data)
}

const blockUser = user_id => {
	const request = axios.post(`${baseUrl}/blockuser/${user_id}`)
	return request.then(response => response.data)
}

const reportUser = user_id => {
	const request = axios.post(`${baseUrl}/reportuser/${user_id}`)
	return request.then(response => response.data)
}

const getUserLists = () => {
	const request = axios.get(`${baseUrl}/userlists`)
	return request.then(response => response.data)
}

const getAllTags = () => {
	const request = axios.get(`${baseUrl}/tags`)
	return request.then(response => response.data)
}

const getUserProfile = (id) => {
	const request = axios.get(`${baseUrl}/profile/${id}`)
	return request.then(response => response.data)
}

const googleLogin = (payload) => {
	const request = axios.get(`/api/api/google-auth`)
	return request.then(response => response.data)
}

const browsingService = {
	getUsers,getRecommendedUsers, likeUser, unlikeUser, blockUser, reportUser,
	getUserLists, getAllTags, getUserProfile,adminDeleteUser,likeUserV2,googleLogin,getPlans,subscribePlan
}

export default browsingService
