import {useState, useMemo, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { createTheme } from '@mui/material/styles'
import {
	AppBar, Container, Toolbar, Box, IconButton,
	Menu, MenuItem, Button, Badge
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Logo from '../../images/matcha_icon_with_heart.png'
import {useDispatch, useSelector} from 'react-redux'
import UserMenu from './UserMenu'
import NotificationMenu from './NotificationMenu'
import {addUserNotification} from "../../reducers/userNotificationsReducer";

const navbar_theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
		},
		secondary: {
			main: '#A928E9',
		},
	}
})

const NavBar = ({ socket }) => {
	const [anchorElNav, setAnchorElNav] = useState(null)

	const user = useSelector(state => state.user)
	const profileData = useSelector(state => state.profile);
	const allNotifications = useSelector(state => state.userNotifications)
	const dispatch = useDispatch()

	const unreadMessageCount = useMemo(() => {
		return allNotifications.filter(notification =>
			notification.read === 'NO' &&
			notification.text.includes('You received a new message')
		).length;
	}, [allNotifications]);

	// useEffect(() => {
	// 	const handleNewNotification = (data) => {
	// 		dispatch(addUserNotification(data));
	// 	};
	//
	// 	socket.on('new_notification', handleNewNotification);
	// 	socket.on('new_message', handleNewNotification);
	//
	// 	return () => {
	// 		socket.off('new_notification', handleNewNotification);
	// 		socket.off('new_message', handleNewNotification);
	// 	};
	// }, [socket, dispatch]);

	let pages = {}

	if (user === '') {
		pages = {
			'Login': '/login',
			'Sign up': '/signup',
		}
	} else {
		pages = {
			'Profile': '/profile',
			'Browsing': '/browsing',
			'Chat': '/chat',
			'Pricing': '/pricing',
		}
	}

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const NavMenuItem = ({ page, path }) => {
		if (page === 'Chat' && unreadMessageCount > 0) {
			return (
				<Button
					color="inherit"
					onClick={handleCloseNavMenu}
					sx={{ mr: 2, position: 'relative' }}
					component={Link}
					to={path}
				>
					{page}
					<Box
						sx={{
							position: 'absolute',
							top: -8,
							right: -8,
							backgroundColor: 'error.main',
							color: 'white',
							borderRadius: '50%',
							fontSize: '0.65rem',
							fontWeight: 'bold',
							width:'20px',
							height:'20px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{unreadMessageCount}
					</Box>
				</Button>
			);
		}
		return (
			<Button
				color="inherit"
				onClick={handleCloseNavMenu}
				sx={{ mr: 2 }}
				component={Link}
				to={path}
			>
				{page}
			</Button>
		)
	}

	return (
		<>
			<AppBar style={{
				position: 'absolute',
				width: '90%',
				left: '50%',
				transform: 'translateX(-50%)',
				top: 20,
				boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
				borderRadius: 10,
				backdropFilter: 'blur(10px)',
				border:'1px solid rgba(0,0,0,0.05)'
			}} theme={navbar_theme}>
				<Container maxWidth='xl'>
					<Toolbar disableGutters>
						<Box
							component={Link}
							to='/'
							sx={{
								display: { xs: 'none', md: 'flex', marginRight: 20 },
								height: '40px'
							}}
						>
							<img src={Logo} alt={'login illustration'}/>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
								keepMounted
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{ display: { xs: 'block', md: 'none' } }}
							>
								{
									Object.entries(pages).map(([page, path]) => (
										<MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={path}>
											{page}
											{page === 'Chat' && unreadMessageCount > 0 && (
												<Box
													sx={{
														ml: 1,
														backgroundColor: 'error.main',
														color: 'white',
														borderRadius: '50%',
														padding: '2px 6px',
														fontSize: '0.75rem',
														fontWeight: 'bold',
													}}
												>
													{unreadMessageCount}
												</Box>
											)}
										</MenuItem>
									))
								}
							</Menu>
						</Box>
						<Box
							sx={{
								flexGrow: 1,
								height: '40px',
								display: { xs: 'flex', md: 'none' }
							}}
							component={Link}
							to='/'
						>
							<img src={Logo}  alt={'login illustration'}/>
						</Box>

						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{
								Object.entries(pages).map(([page, path]) => (
									<NavMenuItem key={page} page={page} path={path} />
								))
							}
						</Box>
						{user && profileData && profileData?.subscription?.days_left > 0 &&
							<div style={{
								padding: '2px 8px',
								fontSize: 12,
								color: 'rgb(144, 92, 244)',
								border: '1px solid rgb(144, 92, 244,0.5)',
								borderRadius: 6,marginRight:20
							}}><b>{profileData?.plans?.plan_name}:</b> Days left {profileData?.subscription?.days_left}</div>
						}

						<UserMenu user={user} socket={socket}/>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	)
}

export default NavBar
